import React from 'react';
import { Form, FormGroup, Input, Label, Row, Col, Button } from 'reactstrap';

const TeamGetInTouch = () => {


    return (
        <>
            <Form>
                <Row>
                    <Col md="6">
                        <FormGroup className='mt-3  text-start'>
                            <Label for="">
                                First name
                            </Label>
                            <Input
                                id=""
                                name=""
                                placeholder=""
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className='mt-3  text-start'>
                            <Label for="">
                                Last name
                            </Label>
                            <Input
                                id=""
                                name=""
                                placeholder=""
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className='mt-3  text-start'>
                            <Label for="">
                                Email address
                            </Label>
                            <Input
                                id=""
                                name=""
                                placeholder=""
                                type="email"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className='mt-3  text-start'>
                            <Label for="">
                                Phone Number
                            </Label>
                            <Input
                                id=""
                                name=""
                                placeholder=""
                                type="number"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mt-3  text-start'>
                            <Label for="exampleSelect">
                                Kickhead path
                            </Label>
                            <Input
                                id="exampleSelect"
                                name="select"
                                type="select"
                                className='bg-black text-white'
                            >
                                <option>---Select---</option>
                                <option>Copywriting</option>
                                <option>Art / Design / Graphic </option>
                                <option>Digital</option>
                                <option>Tech</option>
                                <option>Strategy</option>
                                <option>Behavior study </option>
                                <option>Social </option>
                            </Input>
                        </FormGroup>
                        {/* <FormGroup className=''>
                            <Label for="">
                               
                            </Label>
                            <Input
                                id=""
                                name=""
                                placeholder=""
                                type="number"
                            />
                        </FormGroup> */}
                    </Col>


                    <Col md="12">
                        <FormGroup className='mt-3 text-start'>
                            <Label for=""><i><b>What’s your superpower that makes you powerful, authentic, and unique?</b></i>
                            </Label>
                            <Input
                                id="exampleText"
                                name="text"
                                type="textarea"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mt-3 text-start'>
                            <Label for=""><i><b>What message do you have for the underdogs? </b></i>

                            </Label>
                            <Input
                                id="exampleText"
                                name="text"
                                type="textarea"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mt-3 text-start'>
                            <Label for=""><i><b>How do you like to work with others?</b>  Smooth operator, hustler, fast & furious, lone ranger, collaborator …</i>


                            </Label>
                            <Input
                                id="exampleText"
                                name="text"
                                type="textarea"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mt-3 text-start'>
                            <Label for=""><i><b>What is your kick in life?</b> Drive, Dream, Destiny…</i></Label>
                            <Input
                                id="exampleText"
                                name="text"
                                type="textarea"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mt-3 text-start'>
                            <Label for=""><i><b>Tell us something interesting about you:</b> your favorite bands, food, books, magazines, comedians, shows, or movies.  </i>

                            </Label>
                            <Input
                                id="exampleText"
                                name="text"
                                type="textarea"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12" className='text-center mt-3'>

                        <Button className='button-primary'>
                            SEND MESSAGE
                        </Button>
                    </Col>
                </Row>
            </Form >
        </>
    );
};

export default TeamGetInTouch;
